import React, { useState, useCallback, useRef } from 'react';
import './HomePage.css';
// import prostheticArmImage from '../assets/prosthetic-arm.png';
import useScrollRotation from '../hooks/useScrollRotation';

//Team images
import sohamImage from '../assets/soham.jpg';
import nickImage from '../assets/nick.jpg';
import pranaiImage from '../assets/pranai.jpg';

interface TeamMember {
  name: string;
  bio: string;
  image: string;
  linkedin: string;
}
const teamMembers: TeamMember[] = [
  {
    name: "Nick Cadavid",
    bio: "Nick is a mechanical engineer student at FSU with experience building exoskeletons and a passion for creating advanced prosthetics.",
    image: nickImage,
    linkedin: "https://www.linkedin.com/in/nickcadavid/",
  },
  {
    name: "Soham Mehra",
    bio: "Soham is an industrial and systems engineering student at Virginia Tech with experience in AI/ML in the mortgage and healthcare industries.",
    image: sohamImage,
    linkedin: "https://www.linkedin.com/in/soham-mehra/",
  },
  {
    name: "Pranai Reddy",
    bio: "Pranai is a business management student at Rice University with experience in venture capital and the healthcare industry.",
    image: pranaiImage,
    linkedin: "https://www.linkedin.com/in/pranaireddy/",
  }
];

const HomePage: React.FC = () => {
  const { elementRef, wrapperRef } = useScrollRotation();
  const missionRef = useRef<HTMLElement>(null);
  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  const handleExpand = (section: string) => {
    setExpandedSection(section === expandedSection ? null : section);
  };

  const scrollToMission = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    const targetPosition = missionRef.current?.getBoundingClientRect().top ?? 0;
    const startPosition = window.pageYOffset;
    const distance = targetPosition + startPosition;
    const duration = 2000; // Adjust this value to change the scroll duration (in milliseconds)
    let start: number | null = null;

    const step = (timestamp: number) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const percentage = Math.min(progress / duration, 1);

      window.scrollTo(0, startPosition + distance * easeInOutCubic(percentage));

      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }, []);

  // Easing function for smooth scrolling
  const easeInOutCubic = (t: number): number => {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  };

  return (
    <div className="home-page-wrapper" ref={wrapperRef}>
      <div className="home-page">
        <section className="hero">
          <div className="content">
            <h1>morph</h1>
            <p>Building AI-powered Prosthetics and Robotics of the Future</p>
            <a href="#info-section" className="cta-button" onClick={scrollToMission}>Learn More</a>
          </div>
          <div className="prosthetic-arm-container" ref={elementRef}>
            {/* <div 
              className="prosthetic-arm" 
              style={{backgroundImage: `url(${prostheticArmImage})`}}
            ></div> */}
          </div>
        </section>
        <section className="info-section" ref={missionRef} id='info-section'>
          <div 
            className={`info-subsection team ${expandedSection === 'team' ? 'expanded' : ''}`} 
            onClick={() => handleExpand('team')}
          >
            <h2>Our Team</h2>
            <p>Meet the dedicated team behind Morph, committed to innovation and excellence in AI-powered prosthetics.</p>
            {expandedSection === 'team' && (
              <>
                <div className='team-members'>
                {teamMembers.map((member, index) => (
                <div key={index} className="team-member">
                  <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="member-image-link">
                    <img src={member.image} alt={member.name} className="member-image" />
                  </a>
                  <div className="member-info">
                    <h3>{member.name}</h3>
                    <p className="member-bio">{member.bio}</p>
                  </div>
                </div>
              ))}
                </div>
                <button className="close-button" onClick={() => setExpandedSection(null)}>×</button>
              </>
            )}
          </div>
          <div 
            className={`info-subsection mission ${expandedSection === 'mission' ? 'expanded' : ''}`} 
            onClick={() => handleExpand('mission')}
          >
            <h2>Our Mission</h2>
            <p>At Morph, we are dedicated to improving lives by bridging the gap for amputees by creating affordable, responsive prosthetics that have dynamic functionality without the need for surgery.</p>
            {expandedSection === 'mission' && (
              <>
                <ul className="additional-content">
                  <li>Current upper limb amputees face a lack of affordable, functional prosthetics. Most <strong>existing prosthetics</strong> offer only <strong>3-10 degrees</strong> of freedom, while a <strong>human hand has 27</strong>.</li>
                  <li>These devices rely on preset movements and have a <strong>39% dropout rate</strong> within a year.</li>
                  <li>We are developing a <strong>highly functional</strong>, <strong>non-invasive</strong>, deep learning-powered prosthetic with <strong>21 degrees</strong> of freedom.</li>
                  <li>Our solution eliminates preset controls, delivering <strong>natural movement</strong> and a more convenient alternative for amputees.</li>
                  <li>Individuals who are <strong>athletes</strong>, engaged in <strong>manual labor</strong>, or those longing for <strong>full functionality</strong> will regain the ability to carry out their everyday lives.</li>
                </ul>
                <button className="close-button" onClick={() => setExpandedSection(null)}>×</button>
              </>
            )}
          </div>
        </section>
        <footer className="footer">
          <p>&copy; 2024 Morph. All rights reserved.</p>
          <a href="https://www.linkedin.com/company/morph-labs-ai" target="_blank" rel="noopener noreferrer" className="footer-link">
            LinkedIn
          </a>
        </footer>
      </div>
    </div>
  );
};

export default HomePage;